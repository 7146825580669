<template>
    <error-toast :errorMessage="errorMessage" @resetErroMessage="errorMessage = null">
        <template v-slot:error-message>
            <span class="pl-2 text-md">{{ errorMessage }}</span>
        </template>
    </error-toast>
    <success-toast :successMessage="successMessage" @resetSucessMessage="successMessage = null">
        <template v-slot:success-message>
            <span class="pl-2 text-md">{{ successMessage }}</span>
        </template>
    </success-toast>
    <BackArrow
        :pageTitles="{
            heading: $route.query.vendorId ? 'Update Vendor' : 'Create New Vendor',
            content: 'BACK TO Vendors',
        }"
    />
    <main class="flex bg-white mx-32 my-8 pt-10 pb-20 px-10 rounded-lg shadow text-sm">
        <div class="w-36 mr-12 pt-4">
            <div
                @click="addLogo"
                class="w-full h-36 bg-lightGreyBg rounded-full flex items-center justify-center"
            >
                <img
                    src="@/assets/images/camera-icon.svg"
                    alt="add vendor avatar"
                    id="avatar"
                    :class="{ 'rounded-full w-full h-full': vendorLogo }"
                />
                <input @change="handleFileUpload" type="file" class="vendor-logo hidden" />
            </div>
            <div class="text-center text-sm leading-10" :class="{ hidden: previewing || vendor }">
                Add Vendor Logo
            </div>
        </div>
        <div class="w-3/4">
            <div class="flex capitalize mt-3">
                <div class="w-1/2 mr-7">
                    <label class="leading-8 text-xs">name</label>
                    <input
                        v-model="name"
                        type="text"
                        class="border border-borderColor rounded py-2 px-3 focus:outline-none w-full"
                    />
                </div>
                <div class="w-1/2">
                    <label class="leading-8 text-xs">website</label>
                    <input
                        v-model="website"
                        type="text"
                        class="border border-borderColor rounded py-2 px-3 focus:outline-none w-full"
                    />
                </div>
            </div>
            <div class="flex capitalize mt-3">
                <div class="w-1/2 mr-7">
                    <label class="leading-8 text-xs">phone</label>
                    <input
                        v-model="phone"
                        type="text"
                        class="border border-borderColor rounded py-2 px-3 focus:outline-none w-full"
                    />
                </div>
                <div class="w-1/2">
                    <label class="leading-8 text-xs">email</label>
                    <input
                        v-model="email"
                        type="text"
                        id="email"
                        class="border border-borderColor rounded py-2 px-3 focus:outline-none w-full"
                        :class="{ 'cursor-not-allowed bg-lightGreyBg': $route.query.vendorId }"
                    />
                </div>
            </div>
            <div class="capitalize mt-3">
                <label class="leading-8 text-xs">Address</label>
                <textarea
                    v-model="address"
                    class="border border-borderColor rounded py-2 px-3 focus:outline-none w-full resize-none"
                ></textarea>
            </div>
            <div class="capitalize mt-3 flex justify-between">
                <div v-if="!$route.query.vendorId">
                    <label class="block leading-8 text-xs">password</label>
                    <input
                        v-model="password"
                        style="width: 371px"
                        type="text"
                        placeholder="9E$TuYjGWxVEx3-j"
                        class="border border-borderColor rounded py-2 px-3 focus:outline-none text-green-400"
                    />
                </div>
                <div class="flex items-end" :class="{ 'flex-reverse': $route.query.vendorId }">
                    <span
                        v-if="!$route.query.vendorId"
                        @click="generatePassword"
                        class="w-44 rounded bg-pillApprovedStatus text-white py-2 px-3 text-center mr-5 cursor-pointer"
                        >Generate Password</span
                    >
                    <span
                        v-if="!$route.query.vendorId"
                        @click="createVendor"
                        class="w-44 rounded bg-primaryColor text-white py-2 px-3 text-center cursor-pointer"
                        >Create Vendor</span
                    >
                </div>
                <span
                    v-if="$route.query.vendorId"
                    @click="updateVendor"
                    class="w-44 rounded bg-primaryColor text-white py-2 px-3 mt-6 text-center cursor-pointer "
                    >Update Vendor</span
                >
            </div>
        </div>
    </main>
</template>

<script>
import Request from '@/services/requestHelper'
import BackArrow from '@/components/ui/BackArrow.vue'
import errorFunc from '@/util/error'

export default {
    name: 'newQuote',
    components: { BackArrow },
    async mounted() {
        try {
            const { vendorId } = this.$route.query
            if (!vendorId) {
                return
            }

            const { status, data } = await Request.getRequest(`vendor/${vendorId}`)

            if (status === 200) {
                this.vendor = data.data
                this.name = this.vendor.vendor_details.name
                this.website = this.vendor.website
                this.phone = this.vendor.phone
                this.email = this.vendor.vendor_details.email
                this.address = this.vendor.address
                this.vendorLogo = `http://devops-procurement-api.suburbanfiberco.com${this.vendor.vendor_logo}`

                const avatar = document.querySelector('#avatar')
                avatar.src = `http://devops-procurement-api.suburbanfiberco.com${this.vendor.vendor_logo}`
            }
            this.disableInputField()
        } catch (error) {
            errorFunc(error.response, this.$router)
            this.errorMessage = error.response.data.message
        }
    },
    data() {
        return {
            vendor: null,
            name: null,
            website: null,
            phone: null,
            email: null,
            address: null,
            password: null,
            vendorLogo: null,
            previewing: false,
            errorMessage: null,
            successMessage: null,
            submitting: false,
        }
    },
    methods: {
        disableInputField() {
            const input = document.querySelector('#email')
            input.setAttribute('readonly', true)
        },
        generateRandomString(length) {
            let result = ''
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
            const charactersLength = characters.length
            for (let i = 0; i < length; i += 1) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength))
            }
            return result
        },
        handleFileUpload(event) {
            const [file] = event.target.files
            this.vendorLogo = file

            const avatar = document.querySelector('#avatar')

            avatar.src = URL.createObjectURL(this.vendorLogo)
            this.previewing = true
        },
        generatePassword() {
            this.password = this.generateRandomString(16)
        },
        addLogo() {
            const inputElement = document.querySelector('.vendor-logo')
            inputElement.click()
        },
        async createVendor() {
            try {
                if (this.submitting) {
                    return
                }

                this.submitting = true

                const formdata = new FormData()

                formdata.append('name', this.name)
                formdata.append('website', this.website)
                formdata.append('phone', this.phone)
                formdata.append('email', this.email)
                formdata.append('password', this.password)
                formdata.append('address', this.address)

                if (this.vendorLogo) {
                    formdata.append('vendor_logo', this.vendorLogo)
                }

                const { status } = await Request.postRequest('vendor', formdata)

                if (status === 201) {
                    this.$router.push({ name: 'vendors' })
                }
            } catch (error) {
                this.submitting = false
                errorFunc(error.response, this.$router)
                this.errorMessage = error.response.data.message
            }
        },
        async updateVendor() {
            try {
                if (this.submitting) {
                    return
                }

                this.submitting = true

                const formdata = new FormData()

                formdata.append('name', this.name)
                formdata.append('vendor_id', this.vendor.vendor_details.id)
                formdata.append('website', this.website)
                formdata.append('phone', this.phone)
                formdata.append('address', this.address)

                if (typeof this.vendorLogo === 'object') {
                    formdata.append('vendor_logo', this.vendorLogo)
                }

                const { status, data } = await Request.postRequest('vendor/update', formdata)

                if (status === 201) {
                    this.submitting = false
                    this.successMessage = data.message
                }
            } catch (error) {
                this.submitting = false
                errorFunc(error.response, this.$router)
                this.errorMessage = error.response.data.message
            }
        },
    },
}
</script>
